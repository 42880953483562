const tools = require('../tools');
import $ from 'jquery';
import datepicker from "js-datepicker";

class Filter {
    constructor(elt) {
        this.elt = elt;

        this.wrapper = elt.getElementsByClassName('list-to-update')[0];
        this.listFilter = elt.getElementsByClassName('list-filter')[0];

        this.listFilter = elt.getElementsByClassName('list-filter')[0];
        this.typeFilters = elt.querySelectorAll('input[type=radio][name="typeSelect"]');
        this.typeFilterValue = "";
        this.exhibitionFilter = elt.getElementsByClassName('exhibition-filter')[0];
        this.exhibitionFilters = elt.querySelectorAll('input[type=radio][name="exhibitionSelect"]');
        this.exhibitionFilterValue = "";
        this.dateFilter = elt.getElementsByClassName('date-filter')[0];
        this.resetFilter = elt.getElementsByClassName('reset-filter')[0];
        this.fullEventDetailsHTML = this.wrapper.innerHTML;

        this.baseURL = window.location.href;
        this.loading = document.getElementsByClassName('overlay-loading')[0];

        this.initDetailListener();
        this.initListeners();
    }

    initListeners() {
        let list = this;
        if (list.dateFilter) {
            let dates = {
                de: {
                    days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
                },
                en: {
                    daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                },
                fr: {
                    daysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
                    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
                }
            }
            list.filterDatepicker = datepicker('.date-picker', {
                showAllDates: true,
                startDay: 1,
                position: 'br',
                customDays: dates[tools.getSiteLang()].days,
                customMonths: dates[tools.getSiteLang()].months,
                formatter: (input, date, instance) => {
                    const value = date.toLocaleDateString("fr-FR")
                    input.value = value
                },
                onSelect: (instance, date) => {
                    list.updateFilterDisplayBasedOnDate();
                }
            });
        }

        if (list.typeFilters){
            tools.each(list.typeFilters, tmp => {
                tmp.addEventListener("change", e => {
                    list.typeFilterValue = tmp.value;
                    list.updateFilterDisplayBasedOnDate();
                });
            });
        }

        if (list.exhibitionFilters){
            tools.each(list.exhibitionFilters, tmp => {
                tmp.addEventListener("change", e => {
                    list.exhibitionFilterValue = tmp.value;
                    list.updateFilterDisplayBasedOnDate();
                });
            });
            if (this.baseURL.includes("?exfltr=")){
                let urlParams = new URLSearchParams(window.location.search);
                let exhbId = urlParams.get('exfltr');
                document.getElementById(exhbId).click();

                this.baseURL = this.baseURL.split('?')[0];
                window.history.replaceState({}, document.title, this.baseURL);
            }
        }

        if (list.resetFilter){
            list.resetFilter.addEventListener('click', e => {
                if (list.dateFilter.value != "" || list.typeFilterValue != "" || list.exhibitionFilterValue != ""){
                    list.dateFilter.value= "";
                    if (list.filterDatepicker){
                        list.filterDatepicker.setDate();
                    }
                    if (list.typeFilterValue) {
                        // list.typeFilterValue = "";
                        document.getElementById('resettypeSelect').click();
                    }
                    if (list.exhibitionFilterValue) {
                        // list.exhibitionFilterValue = "";
                        document.getElementById('resetexhibitionSelect').click();
                    }
                    list.updateFilterDisplayBasedOnDate();
                }
            });
        }
    }

    updateFilterDisplayBasedOnDate() {
        let list = this;
        let filterData = "";

        if (list.dateFilter && list.dateFilter.value != "") {
            filterData += "&fromDate=" + list.dateFilter.value;
        }
        if (list.typeFilters && list.typeFilterValue != "") {
            filterData += "&type=" + list.typeFilterValue;
        }
        if (list.exhibitionFilters && list.exhibitionFilterValue != "") {
            filterData += "&exhibition=" + list.exhibitionFilterValue;
        }
        let getURL = list.baseURL + "?ajax=true&filter=true" + filterData;
        // list.wrapper.innerHTML = "";
        $(list.loading).fadeIn(200);
        list.wrapper.style.opacity = 0;
        list.wrapper.style.minHeight = list.wrapper.offsetHeight+"px";
        list.loading.style.minHeight = list.wrapper.offsetHeight+"px";
        $.ajax({
            type: "GET",
            url: getURL,
            success: function (data) {
                list.fullEventDetailsHTML = $(data).find("#"+list.wrapper.id)[0].innerHTML;
                // list.wrapper.innerHTML = list.fullEventDetailsHTML;
                // list.initDetailListener();
                $(list.wrapper).html(list.fullEventDetailsHTML).promise().done(function(){
                    $(list.wrapper).animate({opacity: 1}, 200);
                    list.wrapper.style.minHeight = '0px';
                    $(list.loading).fadeOut(200);
                    list.initDetailListener();
                });
            }
        });
    }

    initDetailListener(){
        tools.each(this.wrapper.getElementsByClassName( 'item-list-entry'), item => {
            let evtInfos = item.getElementsByClassName('item-list-detail')[0];
            let evtDetail = item.getElementsByClassName('item-full-detail')[0];

            if (evtDetail){
                evtInfos.addEventListener('click', e => {
                    $(evtInfos).fadeOut(100);
                    $(evtDetail).show(200);

                    //set anchor
                    let url = new URL(window.location);
                    url.searchParams.set("to", encodeURIComponent(item.id.toString()));
                    window.history.pushState({}, '', url.href);
                    let canonical = document.querySelector('link[rel="canonical"]');
                    if (canonical !== null) {
                        canonical.href = url.href;
                    }
                    let ogurl = document.querySelector('meta[property="og:url"]');
                    if (ogurl !== null) {
                        ogurl.content = url.href;
                    }

                    // evtInfos.style.display = 'none';
                    // evtDetail.style.display = 'flex';
                });
                let closeDetail = evtDetail.getElementsByClassName('close-detail')[0];
                if (closeDetail){
                    closeDetail.addEventListener('click', e => {
                        // evtInfos.style.display = 'flex';
                        // evtDetail.style.display = 'none';
                        $(evtInfos).fadeIn(200);
                        $(evtDetail).hide(200);

                        //remove anchor
                        let url = new URL(window.location);
                        url.searchParams.delete("to");
                        window.history.pushState({}, '', url.href);
                        let canonical = document.querySelector('link[rel="canonical"]');
                        if (canonical !== null) {
                            canonical.href = url.href;
                        }
                        let ogurl = document.querySelector('meta[property="og:url"]');
                        if (ogurl !== null) {
                            ogurl.content = url.href;
                        }
                    });
                }

                //check for detail anchor
                let isAnchor = false;
                let initurl = new URL(window.location);
                let params = new URLSearchParams(initurl.search);
                if (params.get("to")){
                    isAnchor = params.get("to") === encodeURIComponent(item.id);
                }
                if (isAnchor){
                    setTimeout(() => {
                        evtInfos.click();
                        item.scrollIntoView({behavior:"instant",inline: "nearest" , block:"center"});
                    },300)
                }
            }
        });
    }
}

export default Filter;
