const tools = require('../tools');
import $ from 'jquery';

class Murdock{
    constructor() {
        this.initImportActions();
        this.murdockInteractions();
    }

    murdockInteractions(){
        let t = this;
        $("#closeOpenRegisters").click(function(){
            $('.container').slideUp('fast')
        });
        $('.collapsible-container .title').click(function() {
            $(this).next().find('div.scroll-index-item').show();    // ensure former hidden items are visible
            $(this).next().toggle('fast');
            return false;
        }); //.next().hide();

        // Scroll to links
        $(".scroll-index a").click(scrollToAnkerEvent);
        $(".scroll-directory a").click(scrollToAnkerEvent);

        //$("#ocm-directory ul.links a").click(scrollToAnkerEvent);

        //aleph links
        if(window.owc){
            $("#owc-index .aleph, #owc-directory .aleph").click(function(){
                owc($(this).attr("data-alephcode"));
            });
        }else{
            $("#owc-index .aleph, #owc-directory .aleph").each(function(){$(this).hide()});
        }
        if(window.ocm){
            $("#ocm-index .aleph, #textil-index .aleph, #ocm-directory .aleph").click(function(){
                ocm($(this).attr("data-alephcode"));
            });
        }else{
            $("#ocm-index .aleph, #ocm-directory .aleph").each(function(){$(this).hide()});
        }
        // Suchfeld schaltet Eintraege im Index auf unsichtbar
        $('.scroll-index-search').val("");
        $('.scroll-index-search').keyup(function(){
            let v = $(this).val();
            if(v==""){
                $('.scroll-index .container').hide();
                $('.scroll-index .title').show();
            }
        });
        let autoSearchTimeOutId = null;
        $('.scroll-index-search').keypress(function(e) {         // $('.scroll-index-search').keyup(function()
            if(autoSearchTimeOutId!=null){clearTimeout(autoSearchTimeOutId);}
            let searchValue = $(this).val().toLowerCase().trim();
            if(e.which == 13) {
                t.displayMurdockSearchResult();
            }else{
                if (searchValue.length>1) {
                    autoSearchTimeOutId = window.setTimeout('t.displayMurdockSearchResult()',2000);
                }
            }

        });
    }

    displayMurdockSearchResult(){
        let searchValue = $('.scroll-index-search').val().toLowerCase().trim();
        let loaderDisplay = $("#loader_display");

        loaderDisplay.show(0,function(){
            window.setTimeout(function(){
                $('#noresults-message, #noresults-message-textil').hide();
                if (searchValue.length === 0) {
                    $('div.scroll-index div.container').hide();
                    $('div.scroll-index p.title').show();

                }
                if (searchValue.length > 0) {
                    let firstLetter = searchValue.substr(0,1);
                    firstLetter = firstLetter.replace("ä","a");
                    firstLetter = firstLetter.replace("ö","o");
                    firstLetter = firstLetter.replace("ü","u");

                    $('div.scroll-index #container_'+firstLetter).show();
                    $('div.scroll-index div.container:not(#container_'+firstLetter+')').hide();
                    $('div.scroll-index p.title').hide();

                    if (searchValue.length === 1) {
                        $('div.scroll-index #container_'+firstLetter+' div.scroll-index-item').show();
                    }

                    if (searchValue.length>1 ) {

                        let links = $('div.scroll-index > div#container_'+firstLetter+' div.scroll-index-item > a');

                        for (let i=links.length; i!==0; i--) {
                            let aLink = $(links[i-1]);

                            if ( aLink.text().toLowerCase().indexOf(searchValue) === 0 ) {
                                aLink.parent("div.scroll-index-item").show();
                            }
                            else {
                                aLink.parent("div.scroll-index-item").hide();
                            }

                        }
                    }
                    loaderDisplay.hide();
                }
                if (searchValue.length>1) {
                    if ($('div#ocm-index .scroll-index-item:visible').length === 0 && $('div#owc-index .scroll-index-item:visible').length === 0 ) {
                        $('#noresults-message').show();
                    }
                    if ($('div#textil-index .scroll-index-item:visible').length === 0 ) {
                        $('#noresults-message-textil').show();
                    }
                }

            },300)

        });
    }

    initImportActions() {
        let par = $(window.parent.document);
        let actionBar = par.find(".v-actionbar-section:visible").last();
        let container = actionBar.find("#owc-actions-container");
        if (container.length > 0) {
            container.html("");
        } else {
            actionBar.append("<div id='owc-actions-container'></div>");
            par = $(window.parent.document);
            actionBar = par.find(".v-actionbar-section:visible").last();
            container = actionBar.find("#owc-actions-container");
        }

        let importActions = document.getElementById('import_actions');
        if (importActions){
            let actions = importActions.getElementsByClassName('import-action');
            tools.each(actions, action => {
                let actionId = action.dataset.actionId;
                let actionName = action.dataset.actionName;
                let actionLabel = action.dataset.actionLabel;

                container.append('<ul class="v-actionbar-group" ><li id="'+actionId+'" class="v-action"><span class="v-icon icon-import"></span><span class="v-text">'+actionLabel+'</span></li></ul>');
                let importOwcText = par.find("#"+actionId);

                importOwcText.click(e => {
                    importAction(actionName, actionLabel);
                });
            });
        }
    }
}

function importAction(action, actionName){
    if(confirm("Den "+actionName+" machen?")){
        let url = tools.getCurrentURL(true)+"?ajaxCall=true&action="+action+"&ck="+new Date().getTime();
        $.ajax({
            url: url,
            type: 'post',
            async: false,
            success: function(data) {
                alert(actionName+" done: "+data.trim());
                top.location.reload(true);
            },
            error: function(data){
                alert(actionName+" NICHT erfolgreich: "+data.trim());
            }
        });
    }
}

function scrollToAnkerEvent(event) {
    if ($(this).attr('href')) {
        let aName = $(this).attr('href').split("#",2)[1]; // name of anker
        event.preventDefault();
        scrollToAnker(aName)
    }
}

function scrollToAnker(aName) {
    $(".focused-text").removeClass("focused-text");

    let cntr = $("a[name='"+aName+"']");
    // show target container before trying to get the offset ...
    cntr.show().addClass("focused-text");
    cntr.parents(":hidden").show();
    cntr.parent().next().show().addClass("focused-text");

    let offset = cntr.offset().top;
    $('html, body').animate({scrollTop:offset-5}, 500);
}

export default Murdock;