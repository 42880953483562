import Filter from "./list/Filter";
import Collection from "./list/Collection";

const tools = require('./tools');
import {tns} from "tiny-slider/src/tiny-slider";
import Murdock from "./page/murdock";
import Macy from "macy";
import moment from 'moment'
import $ from 'jquery';
window.jQuery = $;
require("@fancyapps/fancybox");

const app = {
    cookieConsentName: 'mkb-cookies-accepted=true',

    init() {
        document.documentElement.classList.remove('no-js');
        this.initPageTransition();
        this.initNav();
        this.imageLazyLoading();
        this.initGalleries();
        this.initMurdock();
        this.initOpenningHours();
        this.initRightColAlignment();
        this.showPopUpDetail();
        this.initCollapsible();
        this.initMasonryGrid();
        this.initItemListFilter();
        this.initCollections();
        this.blogSelectTag();
        this.showBlogEntries();
        this.initTabLayout();
        this.scrollTopFunctionalities();
        this.initVideoTranscripts();
        this.initScreen();
        // this.cookiesAlert();
    },
    initScreen() {
        function overlayCheckTimer() {
            setInterval(()=> {
                const overlay = document.getElementById('screenoverlay');
                if (overlay){
                    const startdateString = overlay.getAttribute('data-startdate');
                    const enddateString = overlay.getAttribute('data-enddate');
                    if (startdateString && enddateString){
                        const date = new Date;
                        const start = new Date(startdateString);
                        const end = new Date(enddateString);
                        if (date > start && date < end) {
                            overlay.classList.add('show');
                        } else {
                            overlay.classList.remove('show');
                        }
                    } else {
                        overlay.classList.remove('show');
                    }
                }
            }, 1000*5);
        }
        function refreshTimer() {
            const interval = document.body.getAttribute('data-interval');
            const timeout = (interval) ? interval * 60000 : 600000;

            setTimeout(() => {
                fetch(window.location.href + "?ajax=true")
                    .then((response) => {
                        if (response.status === 200) {
                            response.text().then((html) => {
                                document.body.innerHTML = html;
                                app.initScreen();
                            })
                        } else {
                            console.log('response code was not 200, skipping and setting new timer');
                            app.initScreen();
                        }
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
            }, timeout);
        }
        function eventCalendar(){
            const pageHeader = getAbsoluteHeight('#logo') + getAbsoluteHeight('.item-list > h2') + getAbsoluteHeight('#qrcode');
            let list = document.querySelector(".screen-page .event-list");
            let leftoverScreenHeight = (window.innerHeight - pageHeader);
            // let leftoverScreenHeight = (window.screen.height - pageHeader);
            //make sure we show something on small screens for editors
            console.log('leftoverScreenHeight: ' + leftoverScreenHeight)
            if (leftoverScreenHeight < 500) {
                console.log('screen small')
                // leftoverScreenHeight = 700;
                leftoverScreenHeight = 1920;
            }

            function fitEventsIntoScreen(){
                //show all events to calculate
                tools.each(document.querySelectorAll(".item-list-entry.hide"), entry => {
                    entry.classList.remove('hide');
                });

                //hide all events that overflow
                while (list.offsetHeight > leftoverScreenHeight) {
                    let entries = document.querySelectorAll(".item-list-entry:not(.hide)");
                    if (entries && entries.length > 0) entries[entries.length - 1].classList.add("hide");
                }
            }

            fitEventsIntoScreen();
            setTimeout(() => {
                fitEventsIntoScreen();
            }, "100");

            window.removeEventListener("resize", eventCalendar);
            window.addEventListener("resize", eventCalendar);
        }

        function getAbsoluteHeight(el) {
            // Get the DOM Node if you pass in a string
            el = document.querySelector(el);

            if (el) {
                var styles = window.getComputedStyle(el);
                var margin = parseFloat(styles['marginTop']) +
                    parseFloat(styles['marginBottom']);

                return Math.ceil(el.offsetHeight + margin);
            } else {
                return 0;
            }
        }
        function refreshQR() {
            const qrContainer = document.getElementById("qrcode");
            if (qrContainer){
                new QRCode(qrContainer, qrContainer.getAttribute('data-url'));
            }
        }

        if (document.querySelector(".screen-page")){
            refreshTimer();
            refreshQR();
            overlayCheckTimer();
            eventCalendar();
        }
    },
    initPageTransition() {
        setTimeout(function(){ document.body.classList.add("loaded") }, 200);
        // tools.each(document.querySelectorAll('a'), link => {
        //     link.addEventListener("click", e => {
        //         if (!link.classList.contains("loaded") && link.target != "_blank"){
        //             e.preventDefault();
        //             link.classList.add("loaded");
        //             document.body.classList.remove("loaded");
        //             setTimeout(e => { link.click(); }, 200);
        //         }
        //     });
        // });
    },
    initNav() {
        const navMain = document.querySelector('#nav_main');
        let navCurrentTarget = null;
        if (navMain) {
            tools.each(navMain.querySelectorAll('li'), navItem => {
                const targetId = navItem.getAttribute('data-trigger');
                if (targetId) {
                    const menuTrigger = document.querySelector(targetId);
                    if (menuTrigger) {
                        navItem.addEventListener("mouseover", e => {
                            let target = e.target;
                            if (target.tagName === "A" || target.classList.contains("sub-menu")){
                                target = target.parentElement;
                            }
                            if (target == navItem && navCurrentTarget != target){
                                tools.each(menuTrigger.parentElement.querySelectorAll('.menu-highlight-content.open'), item => {
                                    item.classList.remove("open");
                                });
                                menuTrigger.classList.add("open");
                                navCurrentTarget = target;
                            }
                        });
                    }
                }
            });

            let mobileMenuOpenLink = document.getElementById('touch_menu');
            let headerNavigation = document.getElementById('header_navigation');
            if (mobileMenuOpenLink && headerNavigation){
                mobileMenuOpenLink.addEventListener('click', e => {
                    e.preventDefault();
                    mobileMenuOpenLink.classList.toggle("selected");
                    headerNavigation.classList.toggle("open");
                    document.body.classList.toggle("nav-open");
                    headerNavigation.parentElement.classList.toggle("fixed");
                    if (!headerNavigation.classList.contains("open")){
                        tools.each(navMain.querySelectorAll('li.open'), item => {
                            item.classList.remove('open');
                        });
                    }
                    tools.each(navMain.querySelectorAll('li.active'), item => {
                        item.classList.add('open');
                        let parentElt = tools.getClosest(item, "li.has-suv-nav");
                        for (parentElt = parentElt.parentNode; parentElt && parentElt !== null; parentElt = tools.getClosest(parentElt.parentNode, "li.has-suv-nav") ) {
                            if(parentElt){
                                parentElt.classList.add('open');
                            }
                        }
                    });
                });
                tools.each(navMain.querySelectorAll('li.has-suv-nav .sub-menu-arrow'), itemWithSuvNav => {
                    itemWithSuvNav.addEventListener('click', e => {
                        tools.each(itemWithSuvNav.parentElement.parentElement.parentElement.querySelectorAll('ul > li.open'), navItem => {
                            if (navItem != itemWithSuvNav.parentElement){
                                navItem.classList.remove('open');
                            }
                        });
                        itemWithSuvNav.parentElement.classList.toggle('open');
                    });
                });
            }

            initMobileScrollListener();
            window.addEventListener('resize', e => {
                initMobileScrollListener();
            });

            function initMobileScrollListener(){
                if (window.innerWidth <= 768){
                    window.addEventListener('scroll', mobileScrollListener);
                } else {
                    window.removeEventListener('scroll', mobileScrollListener);
                }
            }

            let mobileMenuOpenNav = document.getElementById('mobile_open_nav');
            let mobileToTop = document.getElementById('toTop');
            let navBlocked= false,
                navHidden = false,
                scrollPos = 0,
                lastScrollDown = false;
            function mobileScrollListener() {
                if(!navBlocked) {
                    let currentPos = document.body.getBoundingClientRect().top;

                    if (scrollPos > -100 ) {
                        hideNav();
                    } else {
                        showNav();
                    }
                    scrollPos = currentPos;

                }
            }

            function hideNav(){
                // headerNavigation.classList.remove('fixed');
                mobileToTop.classList.remove('fixed');
                mobileMenuOpenNav.classList.remove('fixed');
            }
            function showNav(){
                // headerNavigation.classList.add('fixed');
                mobileToTop.classList.add('fixed');
                mobileMenuOpenNav.classList.add('fixed');
            }
        }
    },

    scrollTopFunctionalities(){
        let toTopElt = document.getElementById('toTop');
        if (toTopElt){
            toTopElt.addEventListener("click", ev => {
                ev.preventDefault();
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        }
    },

    imageLazyLoading() {
        function checkLazyImages() {
            tools.each(document.querySelectorAll('.lazy-img.notset'), lazyImage => {
                if ($(lazyImage).isOnScreen()) {
                    if (lazyImage.tagName == 'IMG'){
                        let imgurl = lazyImage.dataset.image;
                        if (imgurl) {
                            lazyImage.src = imgurl;
                        }
                    } else {
                        let bgImgurl = lazyImage.dataset.image;
                        if (bgImgurl) {
                            lazyImage.style.backgroundImage = bgImgurl;
                        }
                    }
                    lazyImage.classList.remove('notset');
                }
            });
        };

        $(window).scroll(function () {
            checkLazyImages();
        });
        checkLazyImages();
    },

    initRightColAlignment(){
        let colLeft = document.getElementById('colleft');
        let colRight = document.getElementById('colright');
        if (colLeft){
            let fulltext = colLeft.getElementsByClassName('fulltext')[0];
            if (fulltext){
                let topPosition = fulltext.offsetTop;
                let marginTop = 0;
                let paddingTop = 0;
                if (fulltext.innerHTML != ""){
                    // marginTop = window.getComputedStyle(colRight).marginTop;
                    paddingTop = window.getComputedStyle(colRight).paddingTop;
                }
                // colRight.style.marginTop = topPosition + parseInt(marginTop) + "px";
                colRight.style.marginTop = topPosition - parseInt(paddingTop) + "px";
            }
        }
    },

    initMasonryGrid(){
        tools.each(document.getElementsByClassName( 'masonry-grid' ), masonryGrid => {
            let macy = Macy({
                container: masonryGrid,
                trueOrder: false,
                waitForImages: false,
                margin: {
                    x: 45,
                    y: 24
                },
                columns: 3,
                breakAt: {
                    760: {
                        margin: 12,
                        columns: 2
                    },
                    640: {
                        margin: 12,
                        columns: 1
                    }
                }
            });
        });

    },

    initGalleries(){
        tools.each(document.getElementsByClassName( 'tns-carousel' ), carousel => {
            let carouselWrp = carousel.parentElement;
            let controls = carouselWrp.getElementsByClassName('controls-arrows')[0];
            let pagination = carouselWrp.getElementsByClassName('pagination')[0];
            let carouselOptions = {};

            if (carouselWrp.classList.contains("teaser-wrapper")) {
                tools.each(carousel.getElementsByClassName('tns-lazy-img'), teaserElement =>{
                    let defaultImg = teaserElement.dataset.src;
                    teaserElement.dataset.defaultSrc = defaultImg;
                });
                mobileImg();
            }

            if (carousel.classList.contains("grid-gallery")) {
                carouselOptions = {
                    container: carousel,
                    items: 1,
                    edgePadding: 0,
                    controlsContainer: controls,
                    autoWidth: true,
                    // slideBy: 'page',
                    swipeAngle: false,
                    mouseDrag: true,
                    lazyload: true,
                    speed: 400,
                    gutter: 6,
                    responsive: {
                        768: {
                            items: 2,
                            edgePadding: 140,
                            gutter: 10
                        }
                    },
                };
            } else if (carousel.classList.contains("gallery-component")){
                carouselOptions = {
                    container: carousel,
                    controlsContainer: controls,
                    autoWidth: true,
                    gutter: 10,
                    loop: true,
                    rewind: true,
                    // lazyload: true
                }
            } else {
                let speed = carousel.dataset.speed;
                let gallery = carousel.dataset.gallery;
                let itemNb = parseFloat(carousel.dataset.itemNb.replace(",","."));
                let autoplayTimeout = parseInt(carousel.dataset.autoplayTimeout);
                let edgePadding = parseInt(carousel.dataset.edgePadding);
                let autoplay = autoplayTimeout > 0;
                let gutter = carousel.dataset.gutter;

                carouselOptions = {
                    container: carousel,
                    items: itemNb,
                    edgePadding: edgePadding,
                    controlsContainer: controls,
                    autoplay: autoplay,
                    autoplayHoverPause: autoplay,
                    autoplayTimeout: autoplayTimeout,
                    swipeAngle: false,
                    mouseDrag: true,
                    lazyload: true,
                    rewind: true,
                    speed: speed,
                    loop: true,
                    // nav: false,
                    gutter: (gutter/2),
                    mode: gallery,
                    responsive: {
                        768: {
                            gutter: gutter
                        }
                    },
                };
            }

            let tnsCarousel = tns(carouselOptions);

            window.addEventListener('resize', e => {
                mobileImg();
                tnsCarousel.pause();
                tnsCarousel.refresh();
                setTimeout( () => {tnsCarousel.play()}, 500)
            });

            startVideo();

            if (pagination){
                tnsCarousel.events.on( 'indexChanged', evt => {
                    const indexToGoTo = evt.index > tnsCarousel.getInfo().slideCount ? 0 : evt.index - 1;
                    pagination.innerHTML = indexToGoTo+2;
                    startVideo();
                });
            }

            function startVideo(){
                let nextSlideItem = tnsCarousel.getInfo().slideItems[tnsCarousel.getInfo().navCurrentIndex];
                if( nextSlideItem.getElementsByTagName('video').length > 0) {
                    let video = nextSlideItem.getElementsByTagName('video')[0];
                    video.play();
                }
            }

            function mobileImg(){
                if (carouselWrp.classList.contains("teaser-wrapper")) {
                    tools.each(carousel.getElementsByClassName('tns-lazy-img'), teaserElement =>{
                        let defaultImg = teaserElement.dataset.defaultSrc;
                        let mobileImg = teaserElement.dataset.mobileSrc;
                        if (mobileImg != ''){
                            if (window.innerWidth <= 768){
                                teaserElement.src = mobileImg;
                                teaserElement.dataset.src = mobileImg;
                            } else {
                                teaserElement.src = defaultImg;
                                teaserElement.dataset.src = defaultImg;
                            }
                        }
                    });
                }
            }
        });

        tools.each(document.getElementsByClassName('skip-component'), skipComp => {
            skipComp.addEventListener('click', e => {
               let scrollTo = skipComp.parentElement.offsetHeight + skipComp.parentElement.offsetTop;
                window.scrollTo({
                    top: scrollTo,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        });
    },

    initOpenningHours() {

        function getOpenNowString(component) {

            let open = $(component).attr("data-open");
            let close = $(component).attr("data-close");
            let from = $(component).attr("data-from");
            let until = $(component).attr("data-until");
            let since = $(component).attr("data-since");
            let dailyOpeningHours = $(component).attr("data-daily-open");
            let dailyClosingHours = $(component).attr("data-daily-close");
            let openTime = $(component).attr("data-open-time");
            let closeTime = $(component).attr("data-close-time");

            let today = moment(); //Get the current date
            let todayHoursString = today.format("HH:mm");

            let todayH = moment(todayHoursString, "HH:mm");
            let openH = moment(openTime, "HH:mm");
            let closeH = moment(closeTime, "HH:mm");

            if (openH>closeH){
                closeH.add(1, 'days');
            }

            let openDiff = openH.diff(todayH);
            let closeDiff = closeH.diff(todayH);

            if (openDiff > 0) {
                return open + " " + from + " " + dailyOpeningHours;
            } else if (openDiff <= 0 && closeDiff >= 0) {
                return open + " <span></span>" + until + " " + dailyClosingHours;
            } else if (closeDiff < 0) {
                return close + " <span></span>" + since + " " + dailyClosingHours;
            } else {
                return close;
            }
        }

        let openNowContent = document.getElementById("open-now-content");
        tools.each(document.getElementsByClassName("open-now-content"), openNowContent => {
            openNowContent.innerHTML = getOpenNowString(openNowContent);
            window.setInterval(function () {
                openNowContent.innerHTML = getOpenNowString(openNowContent);
            }, 120000);
        })
    },

    showPopUpDetail(){
        let showEventDetail = document.getElementById('dtl_popup');
        if (showEventDetail){
            let dtlPopupId = showEventDetail.dataset.dtlPopup;
            let dtlPopup = document.getElementById(dtlPopupId);
            if (dtlPopup){
                setTimeout(e => {
                    window.scrollTo(0,dtlPopup.offsetTop-45);
                    dtlPopup.click();
                }, 200);
            }
        }

        $('.dtl-popup').fancybox({
            afterShow: function(){
                tools.updateDetailUrl(this);
            },
            afterClose: function(){
                tools.updateDetailUrl(this);
            }
        });
    }
    ,

    initMurdock(){
        let ocm = document.getElementById('ocm-page');
        let owc = document.getElementById('owc-page');
        if (ocm || owc){
            new Murdock();
        }
    },

    initItemListFilter(){
        let itemLists = document.getElementsByClassName('item-list-with-filter');
        if (itemLists) {
            tools.each(itemLists, itemList => new Filter(itemList));
        }
    },

    initCollections(){
        let collections = document.getElementsByClassName('collection-entry');
        if (collections) {
            tools.each(collections, collection => new Collection(collection));

            let collectionLists = document.getElementsByClassName('collection-list-component');
            if (collectionLists) {
                tools.each(collectionLists, collectionList => {
                    let collectionFilter = collectionList.getElementsByClassName('collection-filter')[0];
                    if (collectionFilter){
                        let originFilters = collectionFilter.querySelectorAll('input[type=radio][name="originFilter"]');
                        if (originFilters){
                            let originFilterValue;
                            tools.each(originFilters, tmp => {
                                tmp.addEventListener("change", e => {
                                    originFilterValue = tmp.value;
                                    tools.each(collections, collectionEntry => {
                                        let origin = collectionEntry.dataset.origin;
                                        if (originFilterValue !== ""){
                                            if (originFilterValue == origin){
                                                collectionEntry.style.display = 'block';
                                            } else {
                                                collectionEntry.style.display = 'none';
                                            }
                                        } else {
                                            collectionEntry.style.display = 'block';
                                        }
                                    });
                                });
                            });
                        }
                    }
                });
            }
        }

    },

    initCollapsible(){
        let collapsibleElts = document.getElementsByClassName("collapsible");
        tools.each(collapsibleElts, collapsibleElt => {
            tools.each(collapsibleElt.getElementsByClassName("collapsible-title"), colTitle => {
                let cllpContent = colTitle.parentElement.parentElement;
                let eltID = colTitle.parentElement.id;
                let dtlName = getDtlName(eltID);
                colTitle.addEventListener("click", function() {
                    let parent;
                    let content = this.nextElementSibling;
                    if (content.classList.contains('sub-collapsible-content')){
                        parent = content.closest('.collapsible-content.open');
                    }
                    if (content.style.maxHeight){
                        content.style.maxHeight = null;
                        content.classList.remove('open');
                        updateDetailUrl(dtlName, false);
                    } else {
                        if (!parent){
                            tools.each(cllpContent.getElementsByClassName('open'), openChild => {
                                updateDetailUrl(getDtlName(openChild.parentElement.id), false);
                                openChild.classList.remove('open');
                                openChild.style.maxHeight = null;
                            });
                            tools.each(cllpContent.getElementsByClassName('active'), activeChild => {
                                activeChild.classList.remove('active');
                            });
                        }
                        content.style.maxHeight = content.scrollHeight + "px";
                        content.classList.add('open');
                        if (!parent) {
                            updateDetailUrl(dtlName, true);
                        }
                        if (parent) {
                            let tmp = content;
                            while (tmp.classList.contains('sub-collapsible-content')){
                                tmp = tmp.parentElement.parentElement;
                                if  (tmp.classList.contains('collapsible-content') && tmp.classList.contains('open')){
                                    let tmpMaxHeight = tmp.style.maxHeight;
                                    tmp.style.maxHeight = (content.scrollHeight + parseInt(tmpMaxHeight)) + "px";
                                } else if(tmp.classList.contains('tabcontent')) {
                                    let tmpMaxHeight = parent.style.maxHeight;
                                    parent.style.maxHeight = (content.scrollHeight + parseInt(tmpMaxHeight)) + "px";
                                    break;
                                }else {
                                    break;
                                }
                            }
                        }
                    }
                    this.classList.toggle("active");
                });
                if (colTitle.parentElement.classList.contains("open")){
                    colTitle.click();
                    let topPos = colTitle.parentElement.offsetTop;
                    window.scroll({
                        top: topPos,
                        left: 0,
                        behavior: 'smooth'
                    });
                    colTitle.parentElement.classList.remove("open");
                }
            });
        });

        function getDtlName(eltID){
            let eltIDSplit = eltID.split("_");
            return "cllp-"+eltIDSplit[0]+"/"+eltIDSplit[1];
        }

        function updateDetailUrl(dtlName, isOpen){
            let crntUrl = tools.getCurrentURL();
            let nUrl;

            if (!isOpen){
                nUrl = crntUrl.replace("/"+dtlName, "");
            } else{
                if (!crntUrl.includes(dtlName)) {
                    nUrl = crntUrl.replace(".html", "/" + dtlName + ".html");
                }
            }

            if (nUrl){
                window.history.pushState({}, '', nUrl);
            }
        }
    },

    initTabLayout(){
        let tabLayouts = document.getElementsByClassName("tab-layout");
        tools.each(tabLayouts, tabLayout => {
            let tabContents, tabLinks;
            tabContents = tabLayout.getElementsByClassName("tabcontent");
            tabLinks = tabLayout.getElementsByClassName("tablinks");
            let collapsibleParent;
            if (tabLayout.parentElement.classList.contains("collapsible-content")){
                collapsibleParent = tabLayout.parentElement;
            }

            tools.each(tabLinks, tabLink => {
                let targetId = tabLink.dataset.target;
                tabLink.addEventListener("click", e => {
                    reset();
                    let target = document.getElementById(targetId);
                    target.style.display = "block";
                    tabLink.classList.add("active");
                    if (collapsibleParent) {
                        let parentMaxHeight = parseInt(collapsibleParent.style.maxHeight);
                        if (parentMaxHeight < target.scrollHeight){
                            collapsibleParent.style.maxHeight = (target.scrollHeight + parentMaxHeight) + "px";
                        }
                    }
                });
            });

            function reset(){
                tools.each(tabLinks, tabLink => {
                    tabLink.classList.remove("active");
                });
                tools.each(tabContents, tabContent => {
                    tabContent.style.display = "none";
                });
            }
        });

    },

    blogSelectTag () {
        let $tagFilters = $("div.tag-select");
        if($tagFilters.length > 0) {
            $tagFilters.on("click", ".title", function() {
                if($tagFilters.hasClass("deployed")) {
                    $tagFilters.removeClass("deployed");
                } else {
                    $tagFilters.addClass("deployed");
                }
            });
        }
    },

    showBlogEntries() {
        function checkBlogEntries() {
            tools.each(document.querySelectorAll('.blog-entry.notset'), blogEntry => {
                if ($(blogEntry).isOnScreen()) {
                    let imagewrap = blogEntry.getElementsByClassName('image-wrapper')[0];
                    if (imagewrap){
                        let imgurl = imagewrap.dataset.image;
                        if (imgurl) {
                            imagewrap.style.backgroundImage = imgurl;
                        }
                        blogEntry.classList.remove('notset');
                    }
                }
            });
        };

        $(window).scroll(function () {
            checkBlogEntries();
        });
        checkBlogEntries();
    },

    initVideoTranscripts() {
        tools.each(document.querySelectorAll('.video-transcripts'), transcriptsEmbed => {
            let collapsibleTitle = transcriptsEmbed.getElementsByClassName('collapsible-title')[0];
            let height = transcriptsEmbed.offsetHeight;
            collapsibleTitle.addEventListener('click', e => {
                if (transcriptsEmbed.classList.contains('show-transcripts')){
                    transcriptsEmbed.classList.remove('show-transcripts');
                    transcriptsEmbed.style.paddingTop = "0px";
                    transcriptsEmbed.style.paddingBottom = "0px";
                } else {
                    transcriptsEmbed.classList.add('show-transcripts');
                    let newHeight = transcriptsEmbed.offsetHeight;
                    let pad = (height - newHeight) / 2;
                    transcriptsEmbed.style.paddingTop = pad + "px";
                    transcriptsEmbed.style.paddingBottom = pad + "px";
                }
            });
        });
    },

    cookiesAlert() {
        let cookieAlertElt = document.getElementById('cookie_alert');
        let cookieName = this.cookieConsentName;
        if (cookieAlertElt) {

            if (document.cookie.indexOf(cookieName) === -1) {
                cookieAlertElt.classList.remove('accepted');
            }

            let cookieConsentBtn = document.getElementById('cookie_consent');

            cookieConsentBtn.addEventListener('click', evt => {
                evt.preventDefault();
                let d = new Date();
                d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
                document.cookie = cookieName + ";expires=" + d.toUTCString() + ";path=/";
                cookieAlertElt.classList.add('accepted');
            });
        }
    }
};

window.cookieConsent = {hasStatisticsCookiesConsent : app.hasStatisticsCookiesConsent};
window.sukoaUtils = {dcmadr : tools.dcmadr};

window.jQuery.fn.isOnScreen = function(){

    let win = $(window);

    let viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    let bounds = this.offset();

    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};
document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

