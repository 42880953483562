const tools = {
    ajxGet(url, fallback=null){
        let req = new XMLHttpRequest();
        req.onreadystatechange = function() {
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                if(fallback) {
                    fallback(req);
                }
            }
        };
        req.open('GET', url, true);
        req.send(null);
    },

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    getClosest : function (elem, selector) {
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) return elem;
        }
        return null;
    },

    getCurrentURL : function(excludeParams){
        let url = window.location.href;
        if(excludeParams){
            let i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            let j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    updateDetailUrl: function (detail){
        let dtlName = detail.src.replace("#item_detail_", "");
        let currentUrl = tools.getCurrentURL();
        let nUrl;

        if (currentUrl.includes(dtlName)){
            nUrl = currentUrl.replace("/"+dtlName, "");
        } else{
            nUrl = currentUrl.replace(".html", "/"+dtlName+".html");
        }

        if (nUrl){
            window.history.pushState({}, '', nUrl);
        }
    },
    /* decryp addresses*/
     dcmadr(nnnn){
        let a = "";
        for(let i=0,m=nnnn.length;i < m;i++){
            if(i%3==0){
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href=(a);
    },

    isEditMode() {return document.body.classList.contains('editmode')},
    getSiteLang() {return document.documentElement.lang}
};

module.exports = tools;

