import $ from "jquery";

const tools = require('../tools');
import {tns} from "tiny-slider/src/tiny-slider";
import Player from "@vimeo/player";
window.jQuery = $;
require("@fancyapps/fancybox");

class Collection {
    constructor(elt) {
        this.elt = elt;

        this.eltId = elt.id;
        this.eltName = this.eltId.replace("item_detail_", "");
        this.detail = elt.getElementsByClassName('collection-detail')[0];
        this.thumbnails = elt.getElementsByClassName('collection-thumbnails')[0];
        this.gallery = elt.getElementsByClassName('collection-gallery')[0];
        this.galleryPagination = elt.getElementsByClassName('collection-gallery-pagination')[0];
        this.galleryZoom = elt.getElementsByClassName('collection-gallery-zoom')[0];
        this.galleryControls = this.elt.getElementsByClassName('controls')[0];
        this.thumbnailsControls = this.elt.getElementsByClassName('gallery-arrows')[0];
        this.galleryZoomView = '';
        this.galleryZoomImage = '';

        this.initListeners();
    }

    initListeners() {
        let c = this;
        let detail = this.detail;
        let slider = this.slider;
        this.elt.addEventListener("click", e => {
            $.fancybox.open( detail, {
                touch: false,
                afterShow: e => {
                    updateDetailUrl(true);
                },
                afterClose: e => {
                    detail.classList.remove("fullscreen", "show-detail");
                    c.resetZoomView();
                    updateDetailUrl(false);
                }
            });
            this.initGallery();
            this.initCustomAudioPlayers();
            this.initCustomVideoPlayers();
        });

        let fullscreen = detail.getElementsByClassName("fullscreen-action")[0];
        if (fullscreen){
            fullscreen.addEventListener("click", e => {
                detail.classList.toggle("fullscreen");
                // this.initGallery();
            });
        }

        let showInfos = detail.getElementsByClassName("information-action")[0];
        if (showInfos){
            showInfos.addEventListener("click", e => {
                detail.classList.toggle("show-detail");
            });
        }

        let dtlName = this.eltName;
        function updateDetailUrl(isOpen){
            let currentUrl = tools.getCurrentURL();
            let nUrl;

            if (!isOpen){
                nUrl = currentUrl.replace("/dtl/"+dtlName, "");
            } else{
                if (!currentUrl.includes(dtlName)) {
                    nUrl = currentUrl.replace(".html", "/dtl/" + dtlName + ".html");
                }
            }

            if (nUrl){
                window.history.pushState({}, '', nUrl);
            }
        }
    }

    initGallery() {
        let c = this;
        let galleryPagination = this.galleryPagination;
        let thumbnails = this.thumbnails;
        let thumbnailsControls = this.thumbnailsControls;
        let gallery = this.gallery;
        let galleryControls = this.galleryControls;
        let thumbnailsSlider;
        let sliderId;
        let currentSlide;
        let currentSlideNb = 0;
        let zoomView;
        if (this.thumbnails){
            // Init Thumbnails Slider
            tools.each(thumbnails.querySelectorAll('.gallery-lazy-img.notset'), lazyImage => {
                let imgSrc = lazyImage.dataset.image;
                if (imgSrc) {
                    lazyImage.src = imgSrc;
                }
                lazyImage.classList.remove('notset');
            });
            thumbnailsSlider = tns({
                container: thumbnails,
                "items": 3.3,
                gutter: 8,
                controlsContainer: thumbnailsControls,
                loop: false,
                autoWidth: true,
                mouseDrag: true,
                rewind: true,
                nav: false,
                lazyload: true
            });

        }

        if (this.gallery){
            tools.each(gallery.querySelectorAll('.gallery-lazy-img.notset'), lazyImage => {
                let imgSrc = lazyImage.dataset.image;
                if (imgSrc) {
                    lazyImage.src = imgSrc;
                }
                lazyImage.classList.remove('notset');
            });

            this.slider = tns({
                container: gallery,
                items: 1,
                controlsContainer: galleryControls,
                navContainer: thumbnails,
                autoplay: false,
                autoHeight: false,
                slideBy: 'page',
                swipeAngle: false,
                lazyload: true,
                rewind: true,
                speed: 600,
                // mode: "gallery"
            });
        }
        if (this.gallery && this.thumbnails && this.slider) {

            // Update Pagination
            this.slider.events.on( 'indexChanged', evt => {
                const indexToGoTo = evt.index > this.slider.getInfo().slideCount ? 0 : evt.index - 1;
                thumbnailsSlider.goTo( indexToGoTo );

                c.currentSlideNb = indexToGoTo;
                currentSlide = document.getElementById(sliderId+"-item"+(indexToGoTo+1));
                galleryPagination.innerHTML = indexToGoTo+2;
                c.resetZoomView();
            });

            sliderId = this.gallery.id;
            // currentSlide = document.getElementById(sliderId+"-slide0"+(currentSlideNb+1));
            currentSlide = document.getElementsByClassName("tns-slide-active")[0];
            if (this.galleryZoom){
                let plus = this.galleryZoom.getElementsByClassName('gallery-zoom plus')[0];
                let minus = this.galleryZoom.getElementsByClassName('gallery-zoom minus')[0];
                minus.classList.add('disable');

                plus.addEventListener("click", e => {
                    minus.classList.remove('disable');
                    if (!this.galleryZoomView){
                        this.galleryZoomView = currentSlide;
                        this.galleryZoomImage = this.galleryZoomView.getElementsByTagName('img')[0];
                        // currentSlide.parentNode.appendChild(this.galleryZoomView);
                        this.galleryZoomView.classList.add("active-zoom");
                        this.setDragDrop(this.galleryZoomImage);
                    } else {
                        this.galleryZoomView.classList.add("zoom-x2");
                        this.setDragDrop(this.galleryZoomImage);
                        plus.classList.add('disable');
                    }
                });
                minus.addEventListener("click", e => {
                    plus.classList.remove('disable');
                    if (this.galleryZoomView){
                        if (this.galleryZoomView.classList.contains("zoom-x2")){
                            this.galleryZoomView.classList.remove("zoom-x2");
                            this.setDragDrop(this.galleryZoomImage);
                        } else {
                            c.resetZoomView();
                            minus.classList.add('disable');
                        }
                    }
                });
            }
        }

    }

    resetZoomView(){
        if (this.galleryZoomView){
            // this.galleryZoomView.remove();
            this.galleryZoomView.classList.remove("active-zoom","zoom-x2");
            this.galleryZoomView = '';
            this.galleryZoomImage.style.left = '0px';
            this.galleryZoomImage.style.top  = '0px';
            this.galleryZoom.getElementsByClassName('gallery-zoom plus')[0].classList.remove('disable');
            this.galleryZoom.getElementsByClassName('gallery-zoom minus')[0].classList.add('disable');
        }
    }

    setDragDrop(obj) {
        obj.ondrag = function(e){ return true; }
        obj.mousedown = function(e){ return true; }
        let offset = [0,0];
        let isDown = false;
        let parent = obj.parentNode;
        let minLeft = 0;
        let minTop = 0;
        setTimeout(e => {
            let parentWidth = parent.getBoundingClientRect().width;
            minLeft = (obj.getBoundingClientRect().width - parentWidth)/2;
            let parentHeight = parent.getBoundingClientRect().height;
            minTop = (obj.getBoundingClientRect().height - parentHeight)/2;
        }, 200)


        parent.addEventListener('mousedown', e => {
            isDown = true;
            offset = [
                obj.offsetLeft - e.clientX,
                obj.offsetTop - e.clientY
            ];
        } , true);
        // obj.addEventListener('dragstart', null);
        document.addEventListener('mouseup', e => {
            isDown = false;
        }, true);

        document.addEventListener('mousemove', e => {
            e.preventDefault();
            if (isDown) {
                let tmpLeftPos = (e.clientX + offset[0]);
                if (tmpLeftPos > minLeft){
                    tmpLeftPos = minLeft;
                } else if (tmpLeftPos < -minLeft){
                    tmpLeftPos = -minLeft;
                }
                let tmpTopPos = (e.clientY + offset[1]);
                if (tmpTopPos > minTop){
                    tmpTopPos = minTop;
                } else if (tmpTopPos < -minTop){
                    tmpTopPos = -minTop;
                }
                obj.style.left = tmpLeftPos + 'px';
                obj.style.top  = tmpTopPos + 'px';
            }
        }, true);
    }

    initCustomAudioPlayers(){
        let customAudioPlayers = document.getElementsByClassName('custom-audio');
        if (customAudioPlayers) {
            tools.each(customAudioPlayers, customAudioPlayer => {
                let audio = customAudioPlayer.querySelector('audio');
                let audioPlay = customAudioPlayer.getElementsByClassName('custom-audio-play')[0];
                let audioPause = customAudioPlayer.getElementsByClassName('custom-audio-pause')[0];
                $(audioPause).hide();
                $(audioPlay).show();
                // audio.currentTime = 0;

                audioPlay.addEventListener('click', e => {
                    audio.play();
                    $(audioPlay).hide();
                    $(audioPause).show();
                });

                audioPause.addEventListener('click', e => {
                    audio.pause();
                    $(audioPause).hide();
                    $(audioPlay).show();
                });
            });
        }
    }

    initCustomVideoPlayers(){
        let slider = this.slider;
        let customVideoPlayers = document.getElementsByClassName('custom-video');
        if (customVideoPlayers) {
            tools.each(customVideoPlayers, customVideoPlayer => {
                let videoContainerId = customVideoPlayer.dataset.videoContainerId;
                // let video = customVideoPlayer.querySelector('iframe');
                let video = document.getElementById(videoContainerId);
                let videoPlay = customVideoPlayer.getElementsByClassName('custom-video-play')[0];
                let videoPause = customVideoPlayer.getElementsByClassName('custom-video-pause')[0];
                $(videoPause).hide();
                $(videoPlay).show();

                let player = new Player(video);
                //
                // player.on('play', function() {
                //     console.log('played the video!');
                // });

                videoPlay.addEventListener('click', e => {
                    slider.goTo( slider.getInfo().slideCount );
                    player.play().then(function() {
                        // the video was played
                        $(videoPlay).hide();
                        $(videoPause).show();
                    }).catch(function(error) {
                        console.log(error.name);
                    });
                });

                videoPause.addEventListener('click', e => {
                    player.pause().then(function() {
                        // the video was paused
                        $(videoPause).hide();
                        $(videoPlay).show();
                    }).catch(function(error) {
                        console.log(error.name);
                    });
                });
            });
        }
    }

}

export default Collection;
